/* AskAidenCard.css */

.Stack {
  width: 100%;
  height: 48px;
  padding: 20px 40px 0 40px;
  display: flex;
  justify-content: space-between;
}

.HeaderTypography {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #fff; 
}

.StartButton {
  background-color: #1976d2;
  color: #fff;
}

.MainBox {
  height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: #201f1e;
}

.ChatBox {
  flex-grow: 1;
  overflow-y: auto;
  padding: 2px;
  max-height: 70vh;
  position: relative;
}

.FreshdeskBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}

.container {
  width: 100%;
  height: 48px;
  padding: 20px 40px 0px 40px;
  display: flex;
  justify-content: space-between;
}

.title {
  width: 95px;
  height: 28px;
  font-family: "Segoe UI";
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

/* Add more styles as needed */

.css-1bllldk-MuiFormControl-root-MuiTextField-root input:focus {
  outline: none;
  border: none !important;
}

/* ::-webkit-scrollbar {
  width: 0px;
} */

/* Optional: Add this to hide scrollbar in Firefox */
/* * {
  scrollbar-width: none;
  -ms-overflow-style: none;
} */

::-webkit-scrollbar {
  background: var(--background); /* color of the tracking area */
  width: 7px;
  }
::-webkit-scrollbar-thumb {
  background-color: rgb(39, 39, 39); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.customButton {
  width: 60px;
  height: 32px;
  padding: 6px 20px;
  border-radius: 2px;
  gap: 10px;
  background-color: #eff6fc;
  color: black;
  margin-right: 10px;
}

/* Styles for the text field */
.inputTextField {
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
}

.inputTextField input::placeholder {
  color: #8b8a8a;
  outline: none;
}

.inputTextField .MuiOutlinedInput-notchedOutline {
  border: none;
}

.inputTextField input {
  color: black;
  outline: none;
}

.inputTextField input:focus {
  outline: none;
}

.inputButton {
  background-color: #ffffff;
  color: #605e5c;
}

.placeholderText {
  font-weight: 400;
  color: #323130;
}

.botButton {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.botButton:hover {
  background-color: #ddd;
}

.botButton.clicked {
  background-color: #aaf;
}

/* Add this CSS to your stylesheet or component */
.iconButtonWrapper {
  display: flex;
  margin-top: 10px;
}

.iconButtonWrapper .doneIcon,
.iconButtonWrapper .copyIcon {
  color: rgba(255, 255, 255, 0.7); /* Set the default faded color */
  transition: color 0.3s; /* Add a transition effect for smooth changes */
}

.iconButtonWrapper .doneIcon:hover,
.iconButtonWrapper .copyIcon:hover {
  color: white; /* Set the full color on hover */
}

.iconButtonWrapper .doneIcon:hover {
  color: darken(#000000, 0.2);
}

.iconButtonWrapper .copyIcon:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.citation-superscript {
  color: #005A9E;
  background-color: #EFF6FC;
  margin-left: 2px;
  border-radius: 2px;
  padding: 0 4px 0 4px;
}

.citation-reference {
  background-color: #EFF6FC;
  color: #005A9E;
  border-radius: 4px;
  margin: 0px;
  text-decoration: none;
  font-size: 0.9em;
  padding: 1px 6px 0 6px;
  text-align: left;
}

.citations-area {
  display: flex;
  margin-top: 10px;
  border-top: 2px solid #8A8886;
  border-bottom: 2px solid #8A8886;
  padding: 10px 0 10px 0;
}

.citations-area span.learn-more-text {
  height: 100%;
  display: block;
}

.citations-area .citation-references-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  padding: 0 0 0 10px;
}

@media (max-width: 800px) {


.citations-area {
  display: flex;
  margin-top: 10px;
  overflow: hidden;
  flex-direction: column;
  border-top: 2px solid #8A8886;
  border-bottom: 2px solid #8A8886;
}


.citations-area .citation-references-container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
}

.citation-superscript {
  color: #005A9E;
  background-color: #EFF6FC;
  margin-left: 3px;
  border-radius: 2px;
  padding: 0 4px 0 4px;
}


.citations-area .citation-references-container a.citation-reference {
  background-color: #EFF6FC;
  color: #005A9E;
  border-radius: 4px;
  margin-top: 15px;
  margin-left: 0px;
  text-decoration: none;
  padding: 5px 10px;
  text-align: left;
}

}
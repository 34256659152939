$btn-close-color: '8D8B89';

.table thead .status-column svg{
  height: 20px;
  width: 20px;
  position: relative;
  top: -2px;
  left: 2px;
}

.table.table-dialog thead .status-column svg {
  height: 16px;
  width: 16px;
}

.text-failed svg g {
  stroke: var(--failed-color) !important;
}

.text-not-reporting svg {
  fill: var(--not-reporting-color) !important;
}

.text-recommended svg {
  fill: var(--recommended-color) !important;
}

.text-pending-reboot svg {
  fill: var(--pending-reboot-color) !important;
}

.text-up-to-date svg {
  fill: var(--up-to-date-color) !important;
}

.offcanvas .offcanvas-header .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23" + $btn-close-color + "'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.info-icon i {
  color: var(--info-color);
}
@use "../base/mixins" as m;

.content {
  display: flex;
  margin-top: 110px;

  @include m.desktop {
    margin-top: 57px;
  }

  &__main {
    padding: 16px 30px 50px;
    flex-grow: 1;
    overflow-x: hidden;
  }
}

@use "../base/mixins" as m;

.navbar {
  padding: 11px 20px;
  background: var(--background);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 2;
  width: 100vw;
  display: flex;
  align-items: center;
  top: 0;
}

.nav-container {
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  gap: 16px;

  position: relative;

  @include m.desktop {
    flex-direction: row;
    justify-content: space-between;
  }
}

.user {
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  border: #fff solid 1px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;

  @include m.desktop {
    min-width: 35px;
    min-height: 35px;
    font-size: 14px;
  }

  &-menu {
    min-width: 80px;
    min-height: 80px;
    font-weight: 600;
    font-size: 28px;
    background-color: #3c3c3c;
    color: var(--icon-color);
    border: #fff solid 1.5px;
  }

  &:hover {
    background-color: #252423;
  }
}

.userMenu {
  width: 300px;
  height: 170px;
  position: absolute;
  background-color: #252423;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  z-index: 1;
  top: 78px;
  right: -21px;

  @include m.desktop {
    top: 46px;
    right: -13px;
    width: 350px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding-top: 10px;
    & img {
      width: auto;
      height: 30px;
    }
    & div {
      text-decoration: none;
      color: var(--title-color);
      font-size: 13px;
      cursor: pointer;
      &:hover {
        color: var(--icon-color);
      }
    }
  }

  &__body {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    gap: 15px;
    padding-top: 25px;

    @include m.desktop {
      gap: 6px;
    }

    &--info {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &--name {
      font-weight: 700;
    }

    &--email {
      font-size: 13px;
      color: var(--title-color);
      display: block;
      word-break: break-word;
    }
  }
}

.logos {
  width: 100%;
  display: flex;
  justify-content: center;

  @include m.desktop {
    justify-content: space-between;
  }

  & .logo {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    & img {
      max-width: 90px;
      height: 20px;
    }

    & span {
      font-style: italic;
      font-size: 17px;
      align-self: flex-end;
    }
  }
}

.contact {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;

  @include m.desktop {
    width: auto;
    gap: 13px;
  }

  & .menu {
    cursor: pointer;
    font-size: 16px;
    text-align: left;
    width: 33.33%;

    @include m.desktop {
      display: none;
    }
  }

  & .account {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
    width: 33.33%;

    @include m.desktop {
      font-size: 18px;
    }
  }

  & .nav-link {
    padding: 0px;
    color: #fff;
  }
}

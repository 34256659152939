.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1b86f1;
}

.search {
  position: relative;
}

.search i {
  position: absolute;
  top: -3px;
  left: 10px;
  line-height: 38px;
  color: var(--icon-color);
  transform: rotate(90deg);
}

.search .form-control {
  padding-left: 40px;
  border-radius: 8px;
}

.form-control {
  background-color: var(--background);
  border: 1px solid var(--text-border-color);
}

.form-control:focus,
.form-select:focus {
  background-color: var(--background);
  border: 1px solid var(--title-color);
}

.ms-ContextualMenu,
.ms-Callout-main,
.ms-ContextualMenu-list,
.ms-ContextualMenu-item button {
  background-color: var(--background);
  border-radius: 2px;
}

.ms-ContextualMenu-link {
  font-size: 15px;
  letter-spacing: 0.005em;
  font-weight: normal;
  color: var(--title-color);
}

.ms-ContextualMenu-link i {
  color: var(--title-color);
}

.ms-ContextualMenu-link:hover,
.ms-ContextualMenu-link:active {
  background-color: var(--menu-hover);
  color: var(--text-color);
}

.search .rbt-input-main {
  color: var(--text-color);
  border-radius: 2px;
  height: 32px;
  font-weight: 600;
  font-size: 13px;
  width: 328px;
}

.rbt-menu {
  border-radius: 2px;
  font-size: 15px;
  letter-spacing: 0.005em;
  font-weight: normal;
  color: var(--title-color);
}

.rbt-highlight-text {
  font-size: 16px;
  color: var(--text-color);
}

.dropdown-menu {
  background-color: var(--menu-background);
  transform: translate3d(0px, 20px, 0px) !important;
}

.dropdown-item {
  color: var(--title-color);
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--title-color);
  background-color: var(--menu-hover);
}

.form-select {
  border: 1px solid var(--text-border-color);
  box-sizing: border-box;
  border-radius: 2px;
  height: 32px;
  background-color: var(--background);
  color: var(--title-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.ms-Button {
  background-color: transparent;
  color: var(--title-color);
  border: none;
  height: 32px;
}

.ms-Button:hover {
  background-color: var(--ms-button-hover-color);
  color: var(--title-color);
  border: none;
}

.top-buttons {
  padding: 24px 0 12px 0;
}

.top-buttons .ms-Button {
  font-size: 20px;
}

.ms-Button-icon {
  color: var(--icon-color);
}

.btn {
  font-family: var(--font-family);
  border-radius: 2px;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
}

.btn-custom {
  color: var(--title-color);
  background: var(--background);
  border-radius: 31px;
  border: 1px solid var(--text-border-color);
  padding: 4px 8px;
  gap: 4px;
}

.btn-more-filter {
  margin-left: 16px;
}

.btn-more-filter i {
  font-size: 18px;
}

.btn-customize-graph {
  margin-left: 0.4rem;
}

.btn-customize-graph i {
  font-size: 18px;
}

.btn-primary {
  background: var(--btn-primary-color);
  color: var(--text-color);
}

.btn-default {
  background: var(--background);
  color: var(--text-color);
  border: 1px solid var(--btn-default-border);
}

.btn-default:hover {
  color: var(--text-color);
  background: var(--menu-hover);
}

.btn-custom:hover {
  background: var(--menu-hover);
  color: var(--title-color);
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

.popover {
  max-width: none;
  width: 450px;
  background-color: var(--background);
}

.popover-body label {
  color: var(--text-color);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color: var(--background);
}

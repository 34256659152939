@use "../base/mixins" as m;

.denied {
  text-align: center;
  width: 100%;
  margin-top: 100px;

  &__content {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__message {
    color: #fff;
    font-size: 12pt;

    @include m.desktop {
      font-size: 14pt;
    }
  }
}

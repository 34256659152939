.daterangepicker {
font-family: "Open Sans";
}

.daterangepicker th.month {
  width: auto;
  color: #17181C;
  font-size: 15px;
}

.daterangepicker td.active, 
.daterangepicker td.active:hover{
  background: #1B86F1;
  border-radius: 4px;
}
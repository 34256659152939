@use "../base/mixins" as m;

$sidebar-background-color: #252423;
$sidebar-collapsed-width: 56px;
$sidebar-icon-color: white;
$sidebar-hover-color: #106ebe;
$sidebar-subitem-hover-color: #0e5a8a;

.sidebar {
  background-color: $sidebar-background-color;
  color: $sidebar-icon-color;
  width: 100px;
  min-height: 93vh;
  overflow-x: hidden;
  display: none;
  transition: all 0.3s ease;

  @include m.desktop {
    display: flex;
    flex-direction: column;
    min-width: 200px;
  }

  &--collapsed {
    width: $sidebar-collapsed-width;
    min-width: $sidebar-collapsed-width;
  }

  &__toggle-button {
    align-self: flex-end;
    cursor: pointer;
    margin: 24px 8px 16px 10px;
    background-color: $sidebar-background-color;
    color: $sidebar-icon-color;
    border-radius: 50%;
    font-size: 10px;
    z-index: 100;

    &:hover {
      background-color: darken($sidebar-background-color, 5%);
    }
  }

  &__items {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
    padding: 7px;
    padding-left: 20px;
    transition: background-color 0.2s;

    &:hover {
      background-color: $sidebar-hover-color;
    }

    &--active {
      border-left: #0e5a8a 4px solid;
      background-color: lighten($sidebar-background-color, 5%);
      padding-left: 15px;
      .sidebar__sub-menu {
        display: block;
      }
    }

    &--icon {
      font-size: 21px;
    }

    &--title {
      flex-grow: 1;
      font-size: 16px;
    }
  }

  .sidebar--collapsed &__item {
    padding-left: 7px;

    &--active {
      padding-left: 0rem;
    }
  }
}

.mobile-sidebar {
  background-color: var(--background);
  position: absolute;
  top: 89px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  z-index: 99;
  display: none;

  &--visible {
    height: auto;
    opacity: 1;
    display: block;
  }

  @include m.desktop {
    display: none;
  }

  &__items {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0.8rem 1rem;
    color: $sidebar-icon-color;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $sidebar-hover-color;
    }

    &--active {
      background-color: $sidebar-hover-color;
    }

    &--icon {
      margin-right: 0.5rem;
      padding-left: 3vw;
    }

    &--title {
      flex-grow: 1;
    }
  }
}

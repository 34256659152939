.rdrCalendarWrapper {
  background: var(--background);
}

.rdrDayPassive .rdrDayNumber span {
  color: var(--date-disabled-color);
}

.rdrDayNumber span {
  color: var(--text-color);
}

.rdrMonthAndYearPickers select {
  color: var(--title-color);
  background-color: var(--menu-hover);
  border-radius: 2px;
  padding: 4px 10px 4px 10px;
}

.rdrMonthAndYearPickers select:hover {
  background-color: var(--menu-hover);
}

.rdrNextPrevButton {
  border: 1px solid var(--text-border-color);
  background-color: var(--background);
  border-radius: 2px;
}

.rdrNextPrevButton:hover {
  background-color: var(--menu-hover);
}

.rdrNextButton i {
  border-color: transparent transparent transparent var(--text-color);
}

.rdrPprevButton i {
  border-color: transparent var(--text-color) transparent transparent;
}
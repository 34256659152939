.successMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999999;

  &__content {
    background: linear-gradient(90deg, rgba(18, 18, 18, 1) 70%, rgb(30, 44, 38, 1) 100%);
    border-radius: 0.6rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    width: auto;
    max-width: 30rem;
  }

  &__header {
    margin-bottom: 2rem;
  }
  &__title {
    color: #fff;
    font-size: 1.4rem;
  }

  &__body {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    &--icon {
      color: #34a853;
      font-weight: 700;
      border-radius: 50%;
      border: 0.2rem solid #34a853;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--message {
      color: #c3c3c3;
      font-size: 1rem;
      flex: 1;
      margin: 0;
      width: 90%;
      max-width: 40rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;

    &--button {
      background-color: #2c7b45;
      color: white;
      border: none;
      border-radius: 0.3rem;
      cursor: pointer;
      padding: 0.4rem 1rem;
      font-size: 1rem;
      transition: background-color 0.3s;

      &:hover {
        background-color: #34a853;
      }
    }
  }
}

@use "../base/mixins" as m;

.aidenModal {
  background: #252526;
  color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  width: 90vw;
  overflow-x: hidden;

  @include m.tablet {
    width: 60vw;
  }
  @include m.desktop {
    width: 40vw;
  }
  @include m.large {
    width: 30vw;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    overflow-x: hidden;

    &--title {
      color: white;
      margin-bottom: 0;
    }
    &--icon {
      color: rgba(138, 136, 134, 1);
      cursor: pointer;
      font-size: 1rem;
      transition: 0.3s;

      &:hover {
        color: white;
        transform: rotate3d(0, 0, 1, 90deg);
      }
    }
  }

  &__body {
    padding-top: 20px;
    min-height: 100px;

    &--input {
      width: 100%;
      padding: 5px;
      font-size: 15px;
    }

    &--error {
      color: #e86d73;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    flex: 1;
    align-items: self-end;

    &--cancel {
      transition: all 200ms ease;
      border: 0.1rem solid #0362ac;
      color: #24a0ff;

      &:hover {
        color: white;
        background-color: #a4262c;
      }
    }

    &--confirm {
      background-color: #0078d4;
      transition: all 200ms ease;
      color: white;

      &:hover {
        color: white;
        background-color: #0362ac;
      }
    }
  }
}

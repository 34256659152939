:root {
  --background: #141312;
  --background-second: #1b1a19;
  --text-color: #fff;
  --list-color: #8d8b89;
  --dialog-list-color: #dddbd9;
  --font-family: "Segoe UI", "Noto Sans";
  --border-color: #262626;
  --dialog-border-color: #454443;
  --seperator-color: #1f1212;
  --title-color: #bfbdbb;
  --dialog-title-color: #faf9f8;
  --failed-color: #f3796c;
  --not-reporting-color: #bfbdbb;
  --not-reporting-bg-color: #8d8b89;
  --recommended-color: #ffa031;
  --pending-reboot-color: #42abdd;
  --up-to-date-color: #89d35d;
  --up-to-date-bg-color: #3f9d22;
  --tenant-seperator: #454443;
  --icon-color: #5ebce9;
  --text-border-color: #454443;
  --text-hover-border-color: rgb(16, 110, 190);
  --menu-background: rgb(27, 26, 25);
  --menu-hover: rgb(37, 36, 35);
  --filter-delete-color: #8a8f99;
  --btn-primary-color: #0078d4;
  --btn-default-border: #605e5c;
  --date-disabled-color: rgb(161, 159, 157);
  --ms-button-hover-color: #323130;
  --link-color: #2899f5;
  --info-color: rgb(161, 159, 157);
  color-scheme: dark;
}

* {
  box-sizing: border-box;
  margin: 0;
}

svg.apexcharts-svg {
  background: transparent !important;
}

body {
  font-family: var(--font-family);
  background: var(--background-second);
}


h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #383842;
}

h5 {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #383842;
}

.HeaderTitleClass {
  color: var(--hover-color);
}

.filter-content .date-range {
  background: #ffffff;
  border-radius: 8px;
  padding: 8px;
  margin-left: 16px;
  color: #8a8f99;
  font-weight: 600;
  font-size: 13px;
  border: none;
  line-height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-calendar' viewBox='0 0 16 16'%3E%3Cpath d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 14px center;
  padding-left: 42px;
}

.filter-content .btn-group .btn {
  box-shadow: none;
}

.more-filters .btn-primary {
  background: #1b86f1;
  width: 112px;
  color: #ffffff;
}

#root {
  height: 100vh;
  overflow: auto;
}

.content-page {
  background: var(--background-second);
  padding-top: 16px;
  position: relative;
  overflow: hidden;
  max-width: 80vw;
  padding-top: 100px;
  margin: 0 auto;
}

.content {
  margin: 0 auto;
}

@media (max-width: 768px) {
  .content-page {
    max-width: 85vw;
    margin: 0 auto;
    padding-top: 150px;
  }
  .content {
    margin: 0 auto;
  }
}

.tenant-container {
  border-bottom: 1px var(--tenant-seperator) solid;
  margin-bottom: 16px;
}

.bdr-bottom {
  border-bottom: 1px var(--tenant-seperator) solid;
}

.tenant-container h3 {
  font-size: 28px;
  margin-bottom: 0px;
  color: var(--text-color);
}

.tenant-container h6 {
  font-size: 14px;
  margin-bottom: 0px;
  color: var(--list-color);
}

.main-container {
  background: var(--background);
}

.card {
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  background: var(--background);
  border: none;
}

.selected-filters {
  margin-top: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.selected-filters-dialog {
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.selected-filters span:not(:first-child),
.selected-filters-dialog span:not(:first-child) {
  margin-left: 10px;
}

.selected-filters a {
  margin-left: 16px;
  text-decoration: none;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--filter-delete-color);
}

.selected-filter {
  border: 1px solid var(--text-border-color);
  box-sizing: border-box;
  border-radius: 24px;
  height: 32px;
  padding: 8px 12px;
  color: var(--title-color);
  font-size: 14px;
  line-height: 40px;
  background: var(--background);
}

.selected-filter .close-button {
  margin-left: 5px;
}

.selected-filter a {
  color: var(--filter-delete-color);
  font-size: 18px;
}

.filter-content {
  padding-top: 16px;
}

.more-filters {
  margin-top: 16px;
}

.more-filters p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: #8a8f99;
  margin-bottom: 4px;
}

.summary-number {
  margin-top: 16px;
  border-top: 1px solid var(--seperator-color);
  border-bottom: 1px solid var(--seperator-color);
  padding: 16px 0 16px 0;
}

.summary-number p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-color);
}

.summary-number span.number {
  font-size: 20px;
}

.summary-title {
  padding: 4px 12px;
  border-radius: 4px;
  white-space: nowrap !important;
}

.summary-title span {
  margin-left: 4px;
}

.text-failed {
  color: var(--failed-color) !important;
}

.text-not-reporting {
  color: var(--not-reporting-color) !important;
}

.text-recommended {
  color: var(--recommended-color) !important;
}

.text-pending-reboot {
  color: var(--pending-reboot-color) !important;
}

.text-up-to-date {
  color: var(--up-to-date-color) !important;
}

.status-icon {
  font-size: 20px;
}

.state-text {
  color: var(--text-color);
  padding: 0px 8px 4px;
  border-radius: 24px;
  white-space: nowrap;
}

.summary-failed {
  background: var(--failed-color);
}

.summary-not-reporting {
  background: var(--not-reporting-bg-color);
}

.summary-has-recommendations {
  background: var(--recommended-color);
}

.summary-up-to-date-pending-reboot {
  background: var(--pending-reboot-color);
}

.summary-up-to-date {
  background: var(--up-to-date-bg-color);
}

.bar-failed {
  background: #f3796c;
  padding-left: 5px;
  padding-right: 5px;
}

.bar-not-reporting {
  background: #8a8f99;
  padding-left: 5px;
  padding-right: 5px;
}

.bar-has-recommendations {
  background: #f2a34b;
  padding-left: 5px;
  padding-right: 5px;
}

.bar-current-pr {
  background: #5aabfb;
  padding-left: 5px;
  padding-right: 5px;
}

.bar-current {
  background: #89d35d;
  padding-left: 5px;
  padding-right: 5px;
}

.main-chart {
  margin-top: 16px;
}

.text-bold {
  font-weight: 600;
}

.color-black {
  color: #000000;
}

.color-primary {
  color: #8a8f99;
}

.endpoint-head {
  color: var(--text-color);
  font-size: 20px;
  line-height: 28px;
}

.endpoint-head-value {
  background: #f5f5f6;
  border-radius: 8px;
  padding: 8px;
  margin-left: 4px;
}

.endpoint-head-time {
  font-size: 13px;
  font-weight: 600;
  color: #000;
}

.endpoint-head-value .bi {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  margin-left: 10px;
}

.endpoint-head-value .bi:not(:first-child) {
  margin-right: 10px;
}

.endpoint-head-value a {
  color: #1b86f1;
  font-size: 13px;
  margin-left: 10px;
  text-decoration: none;
}

.btn {
  border-radius: 8px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
  height: 40px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.table-summary {
  margin-top: 16px;
  overflow-x: scroll;
}

.ts-date-title {
  /* color: var(--dialog-title-color) !important; */
  color: var(--dialog-title-color);
}

.ts-date {
  color: #000;
  font-weight: 600;
}

.ts-info {
  color: #292c33;
  font-size: 15px;
  letter-spacing: 0.005em;
}

.ml-16 {
  margin-left: 16px;
}

.pl-24 {
  padding-left: 24px !important;
}
.pl-15 {
  padding-left: 15px !important;
}

.btn-primary {
  background: radial-gradient(29.85% 50% at 50.75% 50%, rgba(27, 134, 241, 0) 0%, #1b86f1 0.01%), #ffffff;
  border: none;
}

.form-control:focus,
.form-select:focus {
  border: 1px solid #3395f8;
  box-sizing: border-box;
  box-shadow: inset 1px 1px 8px rgba(0, 0, 0, 0.08);
}

.apexcharts-toolbar {
  z-index: 0 !important;
}

.offcanvas {
  background-color: var(--background-second);
}

.offcanvas-body {
  padding-top: 0px;
}

.offcanvas-end {
  width: 100%;
}

.dialog-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--list-color);
}

.dialog-title > span:not(:first-child) {
  margin-left: 3px;
}

.dialog-title-label {
  color: rgba(138, 143, 153, 1);
}

.dialog-title-value {
  margin-left: 8px;
  color: var(--text-color);
}

.dialog-content {
  min-height: calc(100vh - 10rem);
}

.dialog-title-wrapper {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.dialog-title-wrapper h3 {
  color: var(--text-color);
  font-weight: 400;
  font-size: 28px;
}

.dialog-title-wrapper h6 {
  color: var(--list-color);
  font-weight: 400;
  font-size: 14px;
}

.machine-table .sticky-top {
  top: -2px;
  height: 60px;
  min-height: 60px;
  background-color: var(--background-second);
  z-index: 1;
}

.sticky-table th:first-child,
.sticky-table td:first-child {
  position: sticky;
  left: -18px;
  border-right: 2px solid var(--dialog-border-color);
  background: var(--background-second);
}

.machine-detail-chart {
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  background: var(--background);
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.endpoint-fact-code {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--title-color);
}

.endpoint-fact-value {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: var(--dialog-title-color);
}

.ms-ComboBox-callout {
  width: 60px;
}

.ms-Label {
  color: var(--title-color);
}

.dropdown-menu {
  overflow-y: auto;
  margin-top: 14px;
  max-height: 70vh;
}











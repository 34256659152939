.tb-endpoint thead th {
  font-size: 14px;
  color: #292c33;
}

.tb-endpoint thead th:first-child {
  border-radius: 4px 0px 0px 4px;
}

.tb-endpoint thead th:last-child {
  border-radius: 0px 4px 4px 0px;
}

.tb-endpoint thead th span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--title-color);
}

.tb-endpoint thead th span.number,
.tb-endpoint thead th span.number span {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: var(--text-color);
}

.table-dialog.tb-endpoint thead th span {
  color: var(--dialog-title-color);
}

.table-dialog.tb-endpoint tbody td {
  color: var(--dialog-title-color);
}

.tb-endpoint tbody td {
  font-size: 14px;
}

.tb-en {
  padding: 6px 8px;
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
  min-width: 60px;
  text-align: right;
}

.tb-en:not(:first-child) {
  margin-left: 8px;
}

.tb-en-disabled {
  background: #f5f5f6;
  color: #d6d8dc;
}

.status-column {
  width: 130px;
}

th,
thead {
  border-color: inherit;
  border-style: solid;
}

.table > :not(:first-child) {
  border-top: none !important;
}

.table a {
  color: var(--link-color);
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.table a:hover {
  text-decoration: underline;
}

.table th .clickable i {
  font-size: 14px;
  color: var(--title-color);
}

.table > :not(caption) > * > * {
  border-bottom: 1px var(--border-color) solid;
}

.table.table-dialog > :not(caption) > * > * {
  border-bottom: 1px var(--dialog-border-color) solid;
}

.scroll {
  scrollbar-width: thin;
  overflow: auto;
}

.border-subtitle {
  border-bottom: 1px var(--dialog-border-color) solid;
}

.truncated-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
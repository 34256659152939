@use "../base/mixins" as m;

.documents {
  &__filters {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__breadcrumb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    color: #606060;
    font-size: 16px;

    &-item {
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: #aaa;
      }

      &.active {
        cursor: default;
        color: #fff;
      }
    }

    &-separator {
      margin: 0 8px;
    }
  }
}

.text-balanced {
  text-wrap: wrap;
  text-overflow: ellipsis;
  vertical-align: sub;
  font-size: 14px;
}

.documentsLink {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    color: #76c6ed;
  }
}

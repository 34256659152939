/* Styling notes
 * ========================================================================== 
 * index.html links to bootstrap styles which include normalizing and the base bootstrap theme.
 * This file contains all other styles in three sections:
 * (1) Styles for the main page (App and AuditorPage components) 
 * (2) Styles that are reusable and global - using short names
 * (3) Styles for specific components - longer names include the component name
 */

/* (1) Main page styles, override bootstrap defaults, and theme variables
 * ========================================================================== */

$color-shade: lightblue

//html colors for charts; keep in sync with javascript constants
$color-error: #FAA
$color-error-dark: #A44
$color-noncompliant: #FB6
$color-noncompliant-dark: #A73
$color-unknown: #D0E8FF
$color-unknown-dark: #7AB
$color-compliant: #CFC
$color-compliant-dark: #7B7

//bootstrap overrides
html
  scroll-behavior: smooth
  overflow: hidden
.modal-title
  margin-left: 8px

//slide-panel overrides
.slide-pane__content
  overflow: hidden !important

//AuditorPage
.auditor-page
  height: 100vh
  overflow: auto
  background-color: #eee
  .has-overlay
    overflow: auto
.auditor-header
  background-color: black
  > img
    height: 30px
    margin: 12px
.auditor-tools
  margin-top: 8px
  > div
    display: inline-block
    margin: 3px
.auditor-blocks
  //text-align: center
.auditor-footer
  min-height: 9em

/* (2) Shared utility styles
 * ========================================================================== */

.warning
  color: red

//use for spans such as columm headings that are not 'a' elements
.clickable
  cursor: pointer
  &:hover
    text-decoration: underline
    text-decoration-color: var(--link-color)

.shaded-tool-bar
  border: 1px solid gray
  background-color: $color-shade
  padding: 1px 4px
  button 
    padding: 2px 4px
    margin: 2px
    font-size: 0.8em

//base class used for the main display blocks (graphs, data tables on main page)
.auditor-block
  display: block
  text-align: left
  background-color: white
  border-radius: 9px
  margin: 0.5em
  padding: 1em

//base class used for the main display blocks that slide in
.slidein-block
  text-align: left
  width: 100%
  height: calc(100vh - 90px)
  overflow: auto

//color markers for use in chart legends
.legend-color-box
  display: inline-block
  width: 12px
  height: 12px
  border-radius: 2px
  margin: 0 6px
  &.compliant
    background-color: $color-compliant
  &.noncompliant
    background-color: $color-noncompliant
  &.unknown
    background-color: $color-unknown
  &.error
    background-color: $color-error

//larger color markers containing numbers inside the marker
.count-color-box
  display: inline-block
  width: 48px
  height: 22px
  border-radius: 3px
  margin: 0 6px
  text-align: center
  font-weight: bold
  &.compliant
    color: $color-compliant-dark
    background-color: $color-compliant
  &.noncompliant
    color: $color-noncompliant-dark
    background-color: $color-noncompliant
  &.unknown
    color: $color-unknown-dark
    background-color: $color-unknown
  &.error
    color: $color-error-dark
    background-color: $color-error

//administrative data entry form
form.a-form
  label
    display: block
    margin: 0
  input
    width: 10em
    margin: 1px 1em 3px 2em
  input[type="checkbox"]
    width: 2em
  select
    margin: 1px 1em 3px 2em
    padding: 2px

//horz row of buttons with spacing
.button-bar
  .btn
    margin: 8px

//table element with borders for dense info-packed data tables
table.dense
  th
    background-color: #eee
    position: sticky
    top: 0
  td
    border-bottom: solid gray 1px
    vertical-align: top
    min-width: 90px
    padding: 5px
  td.bold
    font-weight: bold

/* (3) Private component styles
 * ========================================================================== */

.date-range-select 
  @extend .shaded-tool-bar
  .text 
    display: inline-block
    margin-right: 1em

.mini-metrics
  @extend .shaded-tool-bar
  .main-metrics
    margin-right: 1em

.machine-filter-select
  @extend .shaded-tool-bar
  .quick
    display: inline-block
    width: 8em
    position: relative
    top: 2px
    input
      padding: 1px
      margin: 0 1em
      height: 22px
  .pill
    border: solid 1px gray
    background-color: silver
    border-radius: 6px
    margin: 0 4px
    padding: 0 4px
    position: relative
    top: 3px
    .button-border //"X" button 
      display: inline-block
      background-color: pink
      border-radius: 9px
      height: 16px
      margin-left: 3px
      button
        border: none
        background-color: transparent
        position: relative
        top: -6px
        cursor: pointer

.machine-filter-dialog
  .ftype
    display: inline-block
    margin: 1em
    label
      display: block
      font-weight: bold
      vertical-align: top
    select
      display: block
      width: 12em
      vertical-align: top
      height: 8em

//used by 2 donut chart components
.donut-chart
  &.small
    width: 60px
    height: 60px
  &.large
    width: 120px
    height: 120px

//used by 2 history chart components
.history-chart
  .chart.small
    width: 250px //these numbers are also in code
    height: 60px
  .chart.large
    width: calc(100vw - 320px) //these numbers are also in code
    height: 130px
  .popup
    border: solid gray 1px
    background-color: white
    padding: 0.2em 1em
  .expand-btn-row
    position: relative
    top: -4px
    left: -6px
    height: 12px
    display: flex
    margin: 0 5px 0 45px
    button
      position: absolute
      border: none 
      height: 16px
      width: 12px
      padding: 0
      background-color: transparent
      cursor: pointer
      img
        position: relative
        top: -4px
        left: -2px
    button:hover
      box-shadow: 0 0 3pt 2pt blue
      background-color: white
  .time-row
    display: flex
    justify-content: space-between
    margin-left: 30px

.mini-horz-bar
  width: 150px
  display: inline-block
  height: 12px
  > div
    display: inline-block
    height: 100%

.main-summary-panel
  @extend .auditor-block
  .block
    display: inline-block
    vertical-align: top
  .num-table
    width: 8em
    &.small
      line-height: 1.3em

.machine-detail-panel
  @extend .slidein-block
  .block
    display: inline-block
    vertical-align: top
  .num-table
    width: 12em
    line-height: 1.3em
  th
    vertical-align: top

.admin-home
  height: 500px
  .tab-content
    height: 430px
    overflow: auto
  .admin-table
    width: 100%

/*Admin Screen properties*/
$iconSpace: 10px;


.admin-home {
  color: white;
}

.admin-tabs {
  color: white;
}

.admin-title {
  font-size: small;
  margin-bottom: 40px;
  margin-top: 20px;
  font-weight: lighter;
}


.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.admin-buttons {
  display: flex;
  align-items: center;
  gap: 8px; //Space between buttons 
}


.upload-key {
  margin-bottom: 15px;
}

/* Icons */
.permissions-icon,
.refresh-icon,
.delete-icon,
.edit-icon,
.upload-icon,
.customers-icon {
  margin-left: $iconSpace;
}

.hint-container{
  margin-left: auto; 
}


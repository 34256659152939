@use "../base/mixins" as m;

.uploadModal {
  background: #252526;
  color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  width: 90vw;
  overflow-x: hidden;

  @include m.tablet {
    width: 60vw;
  }
  @include m.desktop {
    width: 40vw;
  }
  @include m.large {
    width: 30vw;
  }

  &__scrollableContent {
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &--title {
      color: white;
      margin-bottom: 0;
    }
    &--icon {
      color: rgba(138, 136, 134, 1);
      cursor: pointer;
      font-size: 1rem;
      transition: 0.3s;

      &:hover {
        color: white;
        transform: rotate3d(0, 0, 1, 90deg);
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    flex: 1;
    align-items: self-end;

    &--cancel {
      transition: all 200ms ease;
      border: 0.1rem solid #0362ac;
      color: #24a0ff;

      &:hover {
        color: white;
        background-color: #a4262c;
      }
    }

    &--confirm {
      background-color: #0078d4;
      transition: all 200ms ease;
      color: white;

      &:hover {
        color: white;
        background-color: #0362ac;
      }
    }
  }
}

.dropzone {
  border: 0.15rem dashed #3a3a3a;
  border-radius: 0.5rem;
  padding: 1.8rem;
  margin-bottom: 1.6rem;
  text-align: center;
  background-color: #1f1f1f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10rem;

  &__icon {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  &__text {
    color: white;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  &__midtext {
    color: #a19f9d;
  }

  &__button {
    color: #0078d4;
    border-bottom: 1px solid #0078d4;
    cursor: pointer;
  }

  &__subtext {
    font-size: 0.9rem;
  }
  &__droptext {
    font-size: 1rem;
  }
}

.fileList {
  margin-bottom: 1.6rem;
  // background-color: #1f1f1f;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background: #605e5c;
    width: 0.6rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 0.4rem;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1%;
    background-color: #1f1f1f;
    padding: 0.5rem 1rem;
    margin: 0.2rem 0;
    border-radius: 0.4rem;
  }

  &__icon {
    text-align: center;
    width: 9%;

    @include m.tablet {
      width: 5%;
    }

    @include m.desktop {
      width: 7%;
    }

    @include m.large {
      width: 6%;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 77%;

    @include m.tablet {
      width: 81%;
    }
  }

  &__name {
    font-size: 0.8rem;
  }

  &__progress {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    &--bar {
      flex-basis: 93%;
    }
    &--percent {
      flex-basis: 5%;
      font-size: 0.7rem;
      color: #8a8886;
    }
  }

  &__size {
    font-size: 0.7rem;
    color: #8a8886;

    &--status {
      color: #d6d689;
      padding-left: 0.2rem;
    }
  }

  &__button {
    width: 5%;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    justify-content: center;

    &:hover {
      color: #d13438;
      transform: rotate(90deg);
    }

    &--cancel {
      color: #d13438;
      cursor: pointer;
    }

    &--verifying {
      font-size: 1rem;
      color: #048df8;
    }
    &--completed {
      font-size: 1rem;
      color: #00ffb1;
    }
  }
}

.dialog {
  &__primary--button {
    background-color: #0078d4;
    color: white;
    transition: all 300ms ease;

    &:hover {
      color: white;
      background-color: #a4262c;
    }
  }
  &__secondary--button {
    transition: all 300ms ease;
    border: 0.1rem solid #0362ac;
    color: #24a0ff;

    &:hover {
      color: white;
      border: 0.1rem solid white;
    }
  }
}
